@use '/src/sass/variables' as *;

.container {
  padding: 1rem;
  position: relative;
}
.content {
  text-align: center;
}
.imagotipo {
  width: 116px;
  height: 56px;
  filter: brightness(0) invert(1);
  transition: all 120ms $easeOutSine;
}
.collapsed {
  .imagotipo {
    width: 56px;
    height: auto;
  }
}
