@use '/src/sass/variables' as *;
@use '/src/sass/mixins';

.container {
  text-align: center;
  padding: 1.5rem;
}

@include mixins.media('md') {
  .container {
    padding: 3rem;
  }
}