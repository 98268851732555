@use '/src/sass/mixins';

.header {
  margin-bottom: 1.25rem;
}

@include mixins.media('lg') {
  .header {
    margin-bottom: 1.5rem;
  }
}
