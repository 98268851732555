@use '/src/sass/variables' as *;
@use '/src/sass/mixins';

.submit {
  width: 100%;
  margin-bottom: 0.5rem;
}

@include mixins.media('md') {
  .submit {
    width: auto;
  }  
}