@use '/src/sass/variables' as *;
@use '/src/sass/mixins';

.container {
  bottom: 64px;
  width: 48px;
  height: 48px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.group {
  width: 48px;
  bottom: 64px;
}

@include mixins.media('md') {
  .container, .group {
    bottom: 44px;
  }
}