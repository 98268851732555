@use '/src/sass/variables' as *;
@use '/src/sass/mixins';

.container {
  &.marginV {
    margin-bottom: 0.75rem;
  }
}
.content {
  padding: 1rem;
  background-color: white;
}

@include mixins.media('md') {
  .container {
    &.marginH {
      margin-right: 1.5rem;
      margin-left: 1.5rem;
    }
    &.marginV {
      margin-bottom: 1.5rem;
    }
  }
  .content {
    padding: 1.5rem;
  }
  .transparent {
    .content {
      background: none;
    }
  }
}