@use '/src/sass/variables' as *;
@use '/src/sass/mixins';

.container {
  overflow-x: hidden;
  input:disabled, select:disabled, textarea:disabled {
    color: rgba(0, 0, 0, 0.88) !important;
  }
}
.title {
  margin-bottom: 0 !important;
  & + span {
    display: inline-block;
    margin-top: 1rem;
  }
}
.delete {
  margin-bottom: 0.5rem;
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

@media (max-width: map-get($breakpoints, 'md')) {
  .actions {
    button {
      width: 100%;
    }
  }
  .title {
    font-size: 1.25rem !important;
  }
}

@include mixins.media('md') {
  .container {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .actions {
    text-align: right;
  }
  .delete {
    text-align: left;
  }
}