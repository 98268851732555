@use '/src/sass/variables' as *;
@use '/src/sass/mixins';

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 52px;
  border-top: 1px solid rgba($color: #000000, $alpha: 0.15);
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
}
.item {
  flex: 1;
  list-style: none;
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 44px;
    svg {
      width: 24px;
      height: 24px;
      fill: rgba($color: #000000, $alpha: 0.45);
      transition: fill 240ms $easeOutSine;
    }
    span {
      font-size: 0.5rem;
      color: rgba($color: #000000, $alpha: 0.65);
      transition: color 240ms $easeOutSine;
      line-height: 1.3;  
    }
  }
  &.active {
    a span {
      color: $primary;
    }
    svg {
      fill: $primary;
    }
  }
}

@include mixins.media('md') {
  .container {
    display: none;
  }
}