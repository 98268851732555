@use '/src/sass/variables' as *;
@use '/src/sass/mixins';

.container {
  background-color: white;
  padding: 1rem;
  min-height: 90vh;
}
.header {
  margin: 0.5rem 0;
  text-align: center;
  img {
    width: 175px;
    height: 85px;
  }
}

@include mixins.media('lg') {
  .container {
    min-height: auto;
    max-width: 448px;
    border-radius: 0.5rem;
    padding: 2rem;
    margin: 4rem auto;
  }
  .header {
    margin-bottom: 2rem;
  }
}