@use '/src/sass/variables' as *;
@use '/src/sass/mixins';

.container {
  min-height: 100vh;
}
.header {
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 1.5rem;
}
.avatar {
  background-color: lighten($primary, 6%);
  cursor: pointer;
}
.avatarImg {
  background-color: rgba($color: #000000, $alpha: 0.15);
  cursor: pointer;
}
.main {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.menu {
  margin-top: 0.5rem;
}
.sider {
  display: none;
}
.displayName {
  display: none;
}

@media (max-width: map-get($breakpoints, 'md')) {
  .container {
    min-height: stretch;
    min-height: -webkit-stretch;
  }
  .header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    &.interior {
      display: none;
    }
  }
  .main {
    margin-bottom: 4rem;
  }
}

@include mixins.media('md') {
  .header {
    padding: 0.5rem 1.5rem;
  }
  .sider {
    display: block;
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .displayName {
    display: inline-block;
    cursor: pointer;
  }
  .content {
    margin-left: 264px;
    transition: margin-left 0.2s ease;
    &.collapsed {
      margin-left: 80px;
    }
  }
  .main {
    gap: 1.5rem;
    margin-bottom: 3rem;
  }
}