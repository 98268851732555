@import 'src/sass/variables';

@mixin media($media) {
  @if $media == 'xs' {
    @media (min-width: map-get($breakpoints, 'xs')) { @content; }
  }
  @else if $media == 'sm' {
    @media (min-width: map-get($breakpoints, 'sm')) { @content; }
  }
  @else if $media == 'md' {
    @media (min-width: map-get($breakpoints, 'md')) { @content; }
  }
  @else if $media == 'lg' {
    @media (min-width: map-get($breakpoints, 'lg')) { @content; }
  }
  @else if $media == 'xl' {
    @media (min-width: map-get($breakpoints, 'xl')) { @content; }
  }
  @else if $media == 'xxl' {
    @media (min-width: map-get($breakpoints, 'xxl')) { @content; }
  }
}

@mixin reset {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}
