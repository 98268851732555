@use '/src/sass/variables' as *;
@use '/src/sass/mixins';

.container {
  padding: 0 1rem;
  background-color: white;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
}

@media (max-width: map-get($breakpoints, 'md')) {
  .container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }
  .back {
    margin-left: -0.5rem;
    flex: 0 1 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .title {
    flex: 1 1 auto;
    font-size: 1.25rem !important;
  }
  .breadcrumb, .description, .action {
    display: none;
  }
  .heading {
    height: 48px;
  }
  .container {
    & + * {
      margin-top: 4rem;
    }
    &.continual {
      & + * {
        margin-top: 3rem;
      }
    }
    &.interior {
      .action {
        display: block;
      }
    }
  }
}

@include mixins.media('md') {
  .container {
    padding: 0.75rem 1.5rem;
    background: none;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  .back {
    display: none;
  }
  .action {
    display: flex;
    gap: 0.75rem;
  }
}